import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

import { host, siteUrl } from 'site/constants';

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    isUnityProject: false,

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.go.zvuk.com/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/zvuk',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    schemaOrg: {
      organizationName: 'Звук',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 600,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s - Звук',
      defaultTitle: '«Звук» - медиа о музыке, которое с вами на одной волне',
      meta: [
        { name: 'description',
          content: '«Звук» — медиакомпания, которая с вами на одной волне. Каждый день наше СМИ работает, чтобы вы получали свежие новости из мира музыки и интересные факты о жизни любимых артистов.',
        },
      ],
    },

    features: {
      enableRetina: true,
      disableAmp: true,
    },

    counterIds: {
      top100: 7660124,
      yandexMetrika: [{ id: 88036962, webvisor: true }],
      mailru: [3374010, 3374184],
      ga4: 'G-H7WGEPEL80',
      rcm: 'RCM-C744',
    },

    specialLinks: [
      '/exports/rss.xml',
      '/media',
    ],

    spammer: {
      ramblerCrmProjectKey: 'zvuk_media',
      digestKey: resolve({
        '*': 'digest-zvuk-stage',
        'production': 'digest-zvuk-prod',
      }),
      termsUrl: 'https://help.rambler.ru/legal/1706/',
    },

    socials: [
      { name: 'yandex_dzen', link: 'https://dzen.ru/zvuk_media' },
      { name: 'livejournalwhite', link: 'https://zvuk-on-air.livejournal.com/' },
      { name: 'podcastlivejournal', link: 'https://zvuk-podcast.livejournal.com/' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UCz-S7VMQNFbGr3NDUGVSDtQ' },
      { name: 'vkontakte', link: 'https://vk.com/zvuk_on_air' },
      { name: 'odnoklassniki', link: 'https://ok.ru/zvuk' },
      { name: 'telegram', link: 'https://t.me/zvuk_on_air' },
    ],

    pushWoosh: {
      applicationCode: '45A6A-07C4F',
      safariWebsitePushID: 'web.com.zvuk.go.push',
      defaultNotificationTitle: 'go.zvuk.com',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    ramblerCommentsAppId: null,

    scrollToSite: {
      stickGoalName: 'prime',
    },
  };
}
